import React from "react";
import { Link } from "gatsby";
import TransitionLink, {
  TransitionPortal
} from "gatsby-plugin-transition-link";
import AniLink from "gatsby-plugin-transition-link/AniLink";

function Grid(props) {
  return (
    <div class="row row-cols-1 row row-cols-md-3">
      {props.data.map(artist => (
        <div class="col">
          <p>
            <AniLink
              cover
              direction="up"
              bg="white"
              partiallyActive={true}
              to={artist.slug}
            >
              <img alt="" class="img-fluid" src={artist.image.fixed.src} />
            </AniLink>
          </p>
          <h3 class="text-center">{artist.name}</h3>
        </div>
      ))}
    </div>
  );
}

export default Grid;
